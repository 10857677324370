import { useContext } from 'react';
import { PageContext } from '../../context/context';
import { alpha, darken, Button, IconButton, Stack } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';

/**
 * @summary builds out the correct button
 * @description builds out the intro screen log interaction button(s)
 * @function
 * @memberof module:components/ActionButtons
 * @param {string} props.buttonLabel - label for the button
 * @param {string} props.logType - label for the title when hovering
 * @param {function} props.onClickLog - function to handle logging event
 * @param {string} props.recordId - record of loged interaction id used to build url
 * @param {component} props.startIcon - icon used in fornt of the button
 * @returns {component}
*/
const IntroScreenLogButton = (props) => {
  const { buttonLabel, logType, onClickLog, recordId, startIcon } = props || {};
  // console.log('from IntroScreenLogButton', recordId, props)
  const { salesforceUser, themeColors } = useContext(PageContext);

  return (
    <Stack spacing={1} direction="row">
      <Button fullWidth variant="contained" size="small"
        sx={{ padding: "8px 0", backgroundColor:themeColors.primary, '&:hover': {
          backgroundColor: darken(themeColors.primary, 0.1),
        }}}
        startIcon={startIcon}
        onClick={onClickLog}
        data-testid={logType === 'email' || logType === 'meeting' ? `ea-log-${logType}` :  'ea-log-case'}>
        {buttonLabel}
      </Button>
      {!!recordId &&
        <IconButton aria-label="launch" size="small" color="primary"
          title={`Open ${logType} in Salesforce`}
          sx={{
            border: `1px solid ${themeColors.primary}`,
            color:themeColors.primary,
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "8px 4px",
            '&:hover': {
              backgroundColor: alpha(themeColors.primary, 0.1),
              borderColor: themeColors.primary
            },
          }}
          onClick={(e) => {
            window.open(`${salesforceUser?.url}/${recordId}`, "_blank")
          }}>
          <LaunchIcon />
        </IconButton>
      }
    </Stack>
  );
};

export default IntroScreenLogButton;
