import { useState, useContext, useEffect } from 'react';
import { PageContext } from '../../context/context';
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

import styles from './Inputs.module.css';

/**
 * @summary Render a checkbox input field with label
 * @function handleOnChange
 * Triggered when selected values for this component is changed.
 * Construct detail object to send to parent for further handling and storage of changes.
 * Update internalValue.
 * @namespace components/inputs/InputCheckbox
 * @description This component renders a checkbox with label
 * @example
 * <InputCheckbox config id label sectionId onChange defaultValue attributes targetField type></InputCheckbox>
*/
const InputCheckbox = (props) => {
  // console.log('from Input Checkbox', props);
  const { config, id, label, onChange, sectionId, title } = props || {};
  const { attributes, defaultValue, targetField, type } = config || {};
  const { handleOnChangeWithDetail, themeColors, valueStorage } = useContext(PageContext);

  /* START Internal Value */
  const [internalValue, setInternalValue] = useState(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        return defaultValue.toLowerCase() === 'true';
      } else {
        return !!defaultValue;
      };
    } else {
      return false;
    };
  });

  useEffect(() => {
    if (defaultValue) {
      handleOnChange(null, defaultValue);
    };
  }, [defaultValue])


  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]) {
      const newValue = valueStorage[sectionId][id].value;
      if (newValue !== internalValue) {
        setInternalValue(newValue);
      };
    };
  }, [valueStorage, sectionId, id]);
  /* END Internal Value */

  const handleOnChange = (e, value) => {
    const detail = {
      inputId: id,
      sectionId: sectionId,
      targetField: targetField,
      type: type,
      value: e ? e.target.checked : value
    };
    if (typeof onChange === 'function') {
      onChange(detail);
    } else {
      handleOnChangeWithDetail(detail);
    };
    setInternalValue(e ? e.target.checked : value);
  };

  return (
    <FormGroup size="small">
      <FormControlLabel
        control={
          <Checkbox
            checked={!!internalValue}
            onChange={handleOnChange}
            sx={{ color: themeColors.tertiary, paddingTop: 0, paddingBottom: 0, paddingRight: "0.25rem" }}
            title={title ? title : ''}
          />
        }
        label={<span className={styles.label}>{label}</span>} />
    </FormGroup>
  );
};

export default InputCheckbox;
