import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ColoredSvg from '../CustomSvg/ColoredSvg';
import renderComponents from '../../utils/renders/renderNestedComponents';
import SectionHeaderButtons from '../ActionButtons/SectionHeaderButtons';
import { useContext } from 'react';
import { PageContext } from '../../context/context';
/**
 * SectionCmp component renders an expandable section using Material-UI's Accordion component. It can display icons, labels,
 * and a configurable list of nested components or fields defined by the `inputFields` in the `config` prop.
 *
 * @component
 * @param {Object} config - The configuration object for the section component.
 * @param {Array} config.inputFields - An array of component configurations to be rendered within the accordion.
 * @param {string} config.iconName - The name of the icon to display next to the section label.
 * @param {string} config.label - The label text for the accordion summary.
 * @param {boolean} config.enableCopy - Determines if copy functionality should be enabled, triggering a display of action buttons.
 * @param {string} config.id - Unique identifier for the section, used by action buttons for copying functionality.
 * @param {boolean} [config.table] - Flag to determine padding style inside accordion details. If true, no padding is applied.
 * @returns {JSX.Element} A section component with a customizable accordion for displaying interactive content.
 *
 * @description
 * This component utilizes context from `PageContext` to access `themeColors`, which are used to style the accordion's borders and expand icon.
 * It is designed to be flexible and reusable for various parts of the application where an expandable UI section is required.
 * The component dynamically renders nested components based on `inputFields`, which can include any form elements or custom components
 * configured for the section.
 */

const SectionCmp = ({ config }) => {
  // console.log('from section cmp', config);
  const inputFields = config?.inputFields;
  const iconName = config?.iconName;
  const { themeColors } = useContext(PageContext);

  return (
    <section style={{ paddingTop: "0.25rem" }}>
      <Accordion defaultExpanded={!config?.defaultClosed} disableGutters
        title={`Expand or collapse ${config.label}`}
        sx={{
          border: `1px solid ${themeColors.tertiary || "rgba(0, 0, 0, 0.2)"}`,
          borderRadius: "0!important",
          boxShadow: "none!important",
        }}>
        <AccordionSummary expandIcon={<ArrowDropUpIcon sx={{ color: themeColors.primary }} />} >
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingRight: "8px" }}>
            <div style={{ display: "flex" }}>
              {iconName &&
                <div style={{ marginRight: "0.5rem", display: "grid" }}>
                  <ColoredSvg iconName={iconName} color={themeColors.tertiary} />
                </div>
              }
              <Typography
                component="h2"
                sx={{
                  margin: "auto",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                  color: "#37474f",
                  lineHeight: "1rem",
                }}>
                {config.label}
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        {config.enableCopy && <SectionHeaderButtons sectionId={config.id} />}
        <AccordionDetails
          sx={{
            borderTop: "1px solid rgba(0, 0, 0, 0.2)",
            padding: config.table ? 0 : ".5rem 1rem 1rem"
          }}>
          <Stack spacing={1}>
            {inputFields && inputFields.length > 0 &&
              inputFields.map((item, index) => (
                item?.type !== "hidden" && renderComponents(item, index, config.id)
              ))
            }
          </Stack>
        </AccordionDetails>
      </Accordion>
    </section >
  );
};

export default SectionCmp;
