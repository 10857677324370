import { useContext, useState } from 'react';
import { Button, ClickAwayListener, List, ListItemButton, ListItemText, ListItemIcon, ListSubheader, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LaunchIcon from '@mui/icons-material/Launch';
import { alpha } from '@mui/material/styles'
import { PageContext } from '../../context/context';

/**
 * DropDownActions is a component that renders a button triggering a dropdown menu to display a list of actions.
 * Each action can be selected, invoking a specified callback function. The dropdown supports categorization of actions into submenus.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.actionList - A list of action categories, each containing a label and a list of menu items.
 * @param {string} props.label - The label displayed on the dropdown button.
 * @param {Function} props.onSelectAction - Callback function triggered when an action is selected from the dropdown.
 * @returns {JSX.Element} A button that toggles a popover containing actionable items.
 *
 * @description
 * This component consists of a Button that, when clicked, opens a Popover directly underneath it. The Popover contains a List of actions,
 * grouped by categories defined in `actionList`. Each action can be selected to perform a specific operation, which is handled by the
 * `onSelectAction` callback, passing the selected action's details to the parent component or handler.
 *
 * Features include:
 * - Dynamic rendering of action items based on provided `actionList`.
 * - Categorization of actions into submenus with headers for easy navigation.
 * - Custom styling to match the theme context, including hover effects.
 * - Accessibility enhancements such as keyboard navigation and focus management within the dropdown.
 *
 * The component leverages Material-UI's Popover and List components to create a flexible and accessible dropdown menu.
 * It is suitable for scenarios where multiple actions need to be presented in an organized and compact form.
 */
const DropDownActions = (props) => {
  const { actionList, label, onSelectAction } = props || {};
  const [buttonAnchor, setButtonAnchor] = useState(null);
  const { themeColors } = useContext(PageContext);

  const handleSelectAction = (menuIndex, itemIndex) => {
    onSelectAction(actionList[menuIndex].menuItems[itemIndex]);
    setButtonAnchor(null); //Close dropdown modal
  };

  const handleUrlButtonClick = (item) => {
    window.open(item.url, "_blank");
    setButtonAnchor(null); //Close dropdown modal
  };

  return (
    <>
      <Button
        aria-haspopup={true}
        aria-label={'open quick create menu'}
        endIcon={!!buttonAnchor ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={(e) => { setButtonAnchor(e.currentTarget) }}
        sx={{
          backgroundColor: "#fff", color: themeColors.primary, borderColor: themeColors.primary, '&:hover': {
            backgroundColor: alpha(themeColors.primary, 0.1),
            borderColor: themeColors.primary
          }
        }}
        title={'open quick create menu'}
        variant="outlined" size="medium"
      >
        {label}
      </Button>

      <Popover
        open={!!buttonAnchor}
        anchorEl={buttonAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <ClickAwayListener onClickAway={() => { setButtonAnchor(null) }}>
          <div className="p-bottom_x-small">
          <button className={'hidden-btn'} onClick={() => { setButtonAnchor(null) }}>Close</button>
            {actionList.length > 0 &&
              actionList.map((menu, menuIndex) => (
                <List key={`${menuIndex}-drop-down`} component="nav" dense
                  sx={{ p: 0 }}
                  subheader={
                    <ListSubheader component="div" sx={{ lineHeight: "36px" }}>
                      {menu.label}
                    </ListSubheader>
                  }>
                  {menu?.menuItems.length > 0 &&
                    menu.menuItems.map((item, itemIndex) => (
                      item.type === "quickAdd" ?
                        <ListItemButton data-testid={`ea-quick-create-${item.label.toLowerCase().replace(/\s/g, '-')}`} autoFocus={itemIndex === 0 ? true : false} tabIndex={0} key={`${itemIndex}-item-menu`} onClick={(e) => handleSelectAction(menuIndex, itemIndex)}>
                          <ListItemIcon sx={{ minWidth: "32px" }}><AddIcon /></ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                        :
                        item.type === "url" ?
                          <ListItemButton key={`${itemIndex}-item-menu`}>
                            <ListItemIcon sx={{ minWidth: "32px" }}><LaunchIcon /></ListItemIcon>
                            <ListItemText><a className={'href-clean'} href={item.url} target="_blank">{item.label}</a></ListItemText>
                          </ListItemButton>
                          :
                          <></>
                    ))
                  }
                </List>
              ))
            }
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

export default DropDownActions;
